<template>
  <div :class="[hookedClass(item), getStatusClass]">
    <v-card
      v-if="(hookWithNoImplicitGet && item.get) || item.put || item.task || item.check"
      :class="{ 'build-step': !hideHeader }"
      tile>
      <v-card-title
        v-if="!hideHeader"
        class="clickable step-vcard-title"
        @click.stop="open = !open">
        <v-row
          no-gutters
          align="center">
          <CyTooltip
            v-if="item.get && isFirstOccurrence(item.get.name)"
            top>
            <template #activator="{ on }">
              <v-icon
                small
                color="accent"
                class="step-new-version"
                v-on="on">
                new_releases
              </v-icon>
            </template>
            {{ $t('newVersion') }}
          </CyTooltip>

          <!-- Add an icon depending the type of item: a task, a get, a put -->
          <v-col
            v-if="item.task"
            cols="2"
            align-self="center"
            class="h6 margin-no-newversion justify">
            <span class="ml-2 step-type">task:</span>
            <span class="ml-1">{{ item.task.name }}</span>
          </v-col>

          <v-col
            v-if="item.get"
            cols="2"
            align-self="center"
            :class="['h6', {
              'margin-no-newversion': !isFirstOccurrence(item.get.name),
            }]">
            <span class="ml-2 step-type">get:</span>
            <span class="ml-1">{{ item.get.name }}</span>
          </v-col>

          <v-col
            v-if="item.put"
            cols="2"
            align-self="center"
            class="h6 margin-no-newversion d-flex">
            <span class="ml-2 step-type">put:</span>
            <span class="ml-1">{{ item.put.name }}</span>
          </v-col>

          <v-col
            v-if="item.check"
            cols="2"
            align-self="center"
            class="h6 d-flex">
            <span class="ml-2 step-type">check:</span>
            <span class="ml-1">{{ item.check.name }}</span>
          </v-col>

          <CyTooltip
            v-if="startedFromNow"
            top>
            <template #activator="{ on: fullDate }">
              <span
                class="started-from-now"
                v-on="fullDate">{{ startedFromNow }}</span>
            </template>
            {{ startedAtFullDate }}
          </CyTooltip>

          <v-divider
            vertical
            class="ml-2"/>

          <!-- Get the version data to display in the div title -->
          <v-col
            v-if="item.get || item.put"
            align-self="center"
            class="resource-version flex-no-grow ml-2">
            <template v-if="item.get && item.get.resource">
              <div
                v-for="(value, key, index) in getResourceVersionMetadata(item.get.resource, true, false)"
                :key="index"
                class="text-left">
                <span>{{ key }}: {{ value }}</span>
              </div>
            </template>
            <template v-if="isCheckBlock && hasContentResourceVersion">
              <div
                v-for="(value, key, index) in getContent.resource.version"
                :key="index"
                class="text-left">
                <span>{{ key }}: {{ value }}</span>
              </div>
            </template>
            <template v-if="item.put && item.put.resource">
              <div
                v-for="(value, key, index) in getResourceVersionMetadata(item.put.resource, true, false)"
                :key="index"
                class="text-left">
                <span>{{ key }}: {{ value }}</span>
              </div>
            </template>
          </v-col>
          <v-spacer/>
          <template v-if="getPlan">
            <v-divider vertical/>
            <CyTooltip top>
              <template #activator="{ on: clearTooltip }">
                <v-btn
                  text
                  small
                  fab
                  class="icon-image-fetch pa-0 mx-2"
                  @click.stop="toggleImageFetching()"
                  v-on="clearTooltip">
                  <v-icon>
                    input
                  </v-icon>
                </v-btn>
              </template>
              {{ $t('imageFetching') }}
            </CyTooltip>
          </template>
          <v-divider
            v-if="item.task"
            vertical/>
          <CyTooltip
            v-if="item.task"
            v-has-rights-to="'ClearTaskCache'"
            top>
            <template #activator="{ on: clearTooltip }">
              <v-btn
                :loading="loadingClearTaskCache"
                text
                small
                fab
                class="icon-clear-task pa-0 mx-2"
                @click.stop="clearTaskCache(item)"
                v-on="clearTooltip">
                <v-icon>
                  fas fa-eraser
                </v-icon>
              </v-btn>
            </template>
            {{ $t('clearCache') }}
          </CyTooltip>
          <v-divider vertical/>
          <v-col class="mr-2 ml-2 flex-no-grow">
            <table class="step-time">
              <tr>
                <td class="dict-key">
                  {{ $t('initialization') }}
                </td>
                <td class="dict-value">
                  {{ getInitTime }}
                </td>
              </tr>
              <tr>
                <td class="dict-key">
                  {{ $t('duration') }}
                </td>
                <td class="dict-value">
                  {{ getStepTime }}
                </td>
              </tr>
            </table>
          </v-col>
          <v-divider vertical/>
          <!-- Add a status to our step -->
          <v-col
            align-self="center"
            class="text-right step-status flex-no-grow pr-2 pl-4  justify">
            <CyTooltip
              v-if="getContent.error"
              bottom>
              <template #activator="{ on: errorTooltip }">
                <v-icon
                  class="error-icon"
                  v-on="errorTooltip">
                  {{ errorIcon }}
                </v-icon>
              </template>
              {{ $t(errorTooltipText) }}
            </CyTooltip>
            <!-- If the step is a task -->
            <v-icon
              v-else-if="_.$get(getContent, 'task', {}).finished && _.$get(getContent, 'task', {}).exitStatus === 0"
              class="success-icon">
              done
            </v-icon>
            <CyTooltip
              v-else-if="_.$get(getContent, 'task', {}).finished && _.$get(getContent, 'task', {}).exitStatus !== 0"
              bottom>
              <template #activator="{ on: errorTaskTootip }">
                <v-icon
                  class="error-icon"
                  v-on="errorTaskTootip">
                  {{ errorIcon }}
                </v-icon>
              </template>
              {{ $t(errorTooltipText) }}
            </CyTooltip>
            <v-progress-circular
              v-else-if="_.$get(getContent, 'task', {}).started"
              :size="18"
              indeterminate
              color="accent"/>
            <!-- If the step is a resource -->
            <v-icon
              v-else-if="_.$get(getContent, 'resource', {}).finished && _.$get(getContent, 'resource', {}).exitStatus === 0"
              class="success-icon">
              done
            </v-icon>
            <CyTooltip
              v-else-if="_.$get(getContent, 'resource', {}).finished && _.$get(getContent, 'resource', {}).exitStatus !== 0"
              bottom>
              <template #activator="{ on: errorResourceTooltip }">
                <v-icon
                  class="error-icon"
                  v-on="errorResourceTooltip">
                  {{ errorIcon }}
                </v-icon>
              </template>
              {{ $t(errorTooltipText) }}
            </CyTooltip>
            <v-progress-circular
              v-else-if="_.$get(getContent, 'resource', {}).started"
              :size="18"
              indeterminate
              color="accent"/>
            <!-- Else, display a pending circle -->
            <v-progress-circular
              v-else
              :size="18"
              :value="0"
              class="indeterminate-icon"
              color="secondary"/>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title
        v-else
        class="d-none"/>
      <!-- The content of the step -->
      <v-card-text
        v-if="open || hideHeader"
        class="content clearfix step-content pa-0">
        <!-- If the resource is a get -->
        <table
          v-if="item.get && (item.get.resource || item.get.type === 'docker-image')"
          class="dictionary resource-version">
          <tr class="metadata">
            <td class="dict-key">
              worker
            </td>
            <td class="dict-value">
              {{ _.$get(getContent, 'worker', '') }}
            </td>
          </tr>
          <tr
            v-for="(dict, key) in getResourceVersionMetadata(item.get.resource, false, true)"
            :key="key"
            class="metadata">
            <td class="dict-key">
              {{ dict.name }}
            </td>
            <td class="dict-value">
              <a
                v-if="isClickableUrl(dict.value)"
                :href="$sanitizeUrl(dict.value)"
                target="_blank"
                rel="noopener noreferrer"
                class="cy-link">
                {{ dict.value }}
              </a>
              <pre v-else>{{ dict.value }}</pre>
            </td>
          </tr>
        </table>
        <!-- If the resource is a put -->
        <table
          v-if="item.put && item.put.resource && !isTerraCostTaskItem"
          class="dictionary">
          <tr class="metadata">
            <td class="dict-key">
              worker
            </td>
            <td class="dict-value">
              {{ _.$get(getContent, 'worker', '') }}
            </td>
          </tr>
          <tr
            v-for="(dict, key) in getResourceVersionMetadata(item.put.resource, false, true)"
            :key="key"
            class="metadata">
            <td class="dict-key">
              {{ dict.name }}
            </td>
            <td class="dict-value">
              <a
                v-if="isClickableUrl(dict.value)"
                :href="$sanitizeUrl(dict.value)"
                target="_blank"
                rel="noopener noreferrer"
                class="cy-link">
                {{ dict.value }}
              </a>
              <pre v-else>{{ dict.value }}</pre>
            </td>
          </tr>
        </table>
        <!-- Image check/get block -->
        <CyViewsBuildLeaf
          v-show="openImageFetching"
          v-for="(childItem, key) in getPlan"
          :key="key"
          :resources="resources"
          :item="childItem"
          :content="content"
          is-check-block
          class="image-fetching"/>
        <!-- If the resource is cost-estimation -->
        <CyTerracostPricingTable
          v-if="isTerraCostTaskItem"
          :pricing-data="[terraCostDataTask.pricingData]"/>
        <table
          v-if="item.task && !isTerraCostTaskItem"
          class="dictionary">
          <tr class="metadata">
            <td class="dict-key">
              worker
            </td>
            <td class="dict-value">
              {{ _.$get(getContent, 'worker', '') }}
            </td>
          </tr>
        </table>
        <table
          v-if="!_.isEmpty(streamingVolumes)"
          class="dictionary">
          <tr
            v-for="(dict, key) in streamingVolumes"
            :key="key"
            class="metadata">
            <td class="dict-key">
              volume
            </td>
            <td class="dict-value">
              {{ dict.volume }}
            </td>
            <td class="dict-key">
              from
            </td>
            <td class="dict-value">
              {{ dict.from }}
            </td>
          </tr>
        </table>
        <pre
          v-if="shouldDisplayContent"
          class="step-content__code-block">
          <virtual-list
            :ref="`terminal-${item.id}`"
            class="virtual-list"
            :keeps="200"
            :data-key="'id'"
            :data-sources="getContent.ansi"
            :data-component="CyViewsBuildLogLineComponent"
            :page-mode="false"
            @scroll="consoleScroll"
            @resized="autoScroll && $refs[`terminal-${item.id}`].scrollToBottom()"/>
          <p
            v-if="getContent.error"
            class="error-message px-4"
            v-html="$sanitizeHtml(getContent.error)"/>
        </pre>
        <pre
          v-else
          class="width-100"/>
      </v-card-text>
    </v-card>

    <template v-if="item.retry">
      <v-tabs v-model="retryItem">
        <span class="mx-8 step-type font-weight-bold d-flex align-center">retry:</span>
        <v-tab
          v-for="(_item, key) in item.retry"
          :key="key"
          class="px-0 ma-0"
          :ripple="false">
          {{ key }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="retryItem">
        <v-tab-item
          v-for="(_item, index) in item.retry"
          :key="index">
          <!-- Retry block -->
          <CyViewsBuildLeaf
            :resources="resources"
            :item="_item"
            :content="content"/>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <CyViewsBuildLeaf
      v-if="isStep(item)"
      :is-try-block="isTryBlock"
      :resources-from-api="resources"
      :item="isStep(item)"
      :content="content"/>

    <!-- Do block -->
    <template v-if="item.do">
      <CyViewsBuildLeaf
        v-for="(_item, key, index) in item.do"
        :key="index"
        class="seq"
        :is-try-block="isTryBlock"
        :resources="resources"
        :item="_item"
        :content="content"/>
    </template>

    <!-- Try block -->
    <template v-if="item.try">
      <CyViewsBuildLeaf
        v-for="(_item, key, index) in item.try"
        :key="index"
        is-try-block
        :resources="resources"
        :item="_item"
        :content="content"/>
    </template>

    <!-- Aggregate block (deprecated in favor of in_parallel) -->
    <template v-if="item.aggregate">
      <CyViewsBuildLeaf
        v-for="(_item, key, index) in item.aggregate"
        :key="index"
        class="seq-in_parallel"
        :is-try-block="isTryBlock"
        :resources-from-api="resources"
        :item="_item"
        :content="content"
        class-in-parallel/>
    </template>

    <!-- In Parallel block -->
    <template v-if="item.in_parallel">
      <CyViewsBuildLeaf
        v-for="(_item, key, index) in item.in_parallel.steps"
        :key="index"
        class="seq-in_parallel"
        :is-try-block="isTryBlock"
        :resources-from-api="resources"
        :item="_item"
        :content="content"
        class-in-parallel/>
    </template>

    <!-- timeout block -->
    <CyViewsBuildLeaf
      v-if="item.timeout"
      class="seq"
      :resources="resources"
      :item="item.timeout.step"
      :content="content"/>

    <div
      v-if="item.on_success || item.on_failure || item.ensure"
      :class="['hook', isChildren(item)]">
      <CyViewsBuildLeaf
        v-if="item.on_success"
        :resources="resources"
        :item="item.on_success"
        :parent-item="item"
        :content="content"
        :class-success="getChildrenClass(item, 'on_success')"/>
      <CyViewsBuildLeaf
        v-if="item.on_failure"
        :resources="resources"
        :item="item.on_failure"
        :parent-item="item"
        :content="content"
        :class-failure="getChildrenClass(item, 'on_failure')"/>
      <CyViewsBuildLeaf
        v-if="item.ensure"
        :resources="resources"
        :item="item.ensure"
        :parent-item="item"
        :content="content"
        :class-ensure="getChildrenClass(item, 'ensure')"/>
    </div>
  </div>
</template>

<script>
import 'moment-duration-format'
import { mapActions } from 'vuex'
import CyTerracostPricingTable from '@/components/terracost/pricing-table.vue'
import CyViewsBuildLogLine from '@/components/views/build-log-line.vue'
import REGEX from '@/utils/config/regex'
import { checksPass } from '@/utils/helpers'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export const REFRESH_INTERVAL_TIMER = 1000

export default {
  name: 'CyViewsBuildLeaf',
  components: {
    CyTerracostPricingTable,
  },
  props: {
    resources: {
      type: Object,
      default: () => ({}),
    },
    classSuccess: {
      type: Boolean,
      default: false,
    },
    classFailure: {
      type: Boolean,
      default: false,
    },
    classInParallel: {
      type: Boolean,
      default: false,
    },
    classEnsure: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => ({}),
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    parentItem: {
      type: Object,
      default: () => ({}),
    },
    isCheckBlock: {
      type: Boolean,
      default: false,
    },
    isTryBlock: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    autoScroll: true,
    open: false,
    openImageFetching: false,
    highlighted: false,
    dateNow: null,
    refreshIntervalTimerID: null,
    loadingClearTaskCache: false,
    retryItem: null,
    CyViewsBuildLogLineComponent: CyViewsBuildLogLine,
  }),
  computed: {
    getContent () {
      return this.content[this.item.id] || {}
    },
    getPlan () {
      return _.get(this.content[this.item.id], 'plan', null)
    },
    startedAtFullDate () {
      return moment.unix(_.get(this.getContent, 'time.started_at')).format('LLL') // eslint-disable-line you-dont-need-momentjs/format
    },
    startedFromNow () {
      if (!_.get(this.getContent, 'time.started_at')) return false
      return $date.$formatTimeAgo(_.get(this.getContent, 'time.started_at'), { strict: true })
    },
    hookWithNoImplicitGet () {
      // if the put has the same name as the get in the onsuccess or on failure hooks we consider that it's an implicit get
      if (_.get(this.parentItem, 'step.put.name', 'parentItem') === _.get(this.item, 'get.name', 'item')) {
        const resource = _.get(this.content, `${this.item.id}.resource`)
        const exitStatus = _.get(resource, 'exitStatus')
        // if the resource has an error or had a bad exit status, show it
        const hasFinished = !!_.get(resource, 'finished')
        const hasBadExitStatus = _.isNumber(exitStatus) && exitStatus !== 0
        const hasError = _.get(this.content, `${this.item.id}.error`, false)

        return (hasFinished && hasBadExitStatus) || hasError
      }
      return true
    },
    getStatusClass () {
      if (this.classSuccess) return 'on_success'
      if (this.classFailure) return 'on_failure'
      if (this.classEnsure) return 'ensure'
      if (this.classInParallel) return 'in_parallel'
      return ''
    },
    getInitTime () {
      if (!_.get(this.getContent, 'time.init_at')) return '-'
      if (!_.get(this.getContent, 'time.started_at')) {
        const diff = $date.$diff($date.fromUnixTime(_.get(this.getContent, 'time.init_at')), $date.fromUnixTime(this.dateNow))
        return `${diff.days}d ${diff.hours}h ${diff.minutes}m ${diff.seconds}s`
      }
      const diff = $date.$diff($date.fromUnixTime(_.get(this.getContent, 'time.init_at')), $date.fromUnixTime(_.get(this.getContent, 'time.started_at')))
      return `${diff.days}d ${diff.hours}h ${diff.minutes}m ${diff.seconds}s`
    },
    getStepTime () {
      if (!_.get(this.getContent, 'time.started_at')) return '-'
      if (!_.get(this.getContent, 'time.finished_at')) {
        const $diff = $date.$diff($date.fromUnixTime(_.get(this.getContent, 'time.started_at')), $date.fromUnixTime(this.dateNow))
        return `${$diff.days}d ${$diff.hours}h ${$diff.minutes}m ${$diff.seconds}s`
      }
      const diff = $date.$diff($date.fromUnixTime(_.get(this.getContent, 'time.started_at')), $date.fromUnixTime(_.get(this.getContent, 'time.finished_at')))
      return `${diff.days}d ${diff.hours}h ${diff.minutes}m ${diff.seconds}s`
    },
    isTerraCostTaskItem () {
      return this.terraCostDataTask?.id === this.item.id
    },
    errorIcon () {
      return this.isTryBlock ? 'error_outline' : 'error'
    },
    errorTooltipText () {
      return this.isTryBlock ? 'tryErrorIconTooltip' : 'errorIconTooltip'
    },
    hasContentResourceVersion () {
      return this.item.get && _.get(this.getContent, 'resource.version')
    },
    shouldDisplayContent () {
      const hasAnsi = !!_.$get(this.getContent, 'ansi')
      const hasError = !!_.$get(this.getContent, 'error')
      const hasTerraCostData = this.isTerraCostTaskItem

      return (hasAnsi || hasError) && this.highlightLine() && !hasTerraCostData
    },
    streamingVolumes () {
      return _.$get(this.getContent, 'streaming', null)
    },
    terraCostDataTask () {
      if (this.item?.put?.type !== 'cycloid-resource') return

      let pricingData = {}

      const task = _.chain(_.entries(this.content))
        .find((entry) => {
          const ansiEntry = _.chain(entry?.[1]?.ansi)
            .values()
            .filter(Boolean)
            .find(({ log }) => _.startsWith(log, '{"planned_cost"'))
            .value()

          if (!_.isEmpty(ansiEntry?.log)) pricingData = JSON.parse(ansiEntry.log)
          return ansiEntry
        })
        .map((entry) => (typeof entry === 'string' ? entry : { ...entry, pricingData }))
        .value()

      return _.$isEmpty(task) ? {} : { ...task[1], id: task[0] }
    },
  },
  watch: {
    open () {
      this.autoScroll = false
      if (!this.open) {
        this.openImageFetching = false
      }
    },
  },
  beforeDestroy () {
    window.clearInterval(this.refreshIntervalID)
  },
  mounted () {
    window.clearInterval(this.refreshIntervalTimerID)
    this.dateNow = Date.now() / 1000
    this.refreshIntervalTimerID = window.setInterval(() => {
      this.dateNow = Date.now() / 1000
    }, REFRESH_INTERVAL_TIMER)

    // Force opening the expansion panel if hash is set (= if coming from shared URL)
    if (this.$route.hash) this.handleTerminalOpening(true, false)

    /*
     * Adding dynamic watchers here. We could open the terminal directly in the mounted hook.
     * But after some tests, it appears that rendering the ansi + virtual scroll list is
     * really slowing the ansi parser (ansiTerminal function). That means the div open, but
     * nothing happens. The user may be totally confused.
     * By knowing that a task or resource finished, we ensure that the parsing is finished.
     * The counterpart is that we can't share a line (I mean opening) on a live log. But I'm
     * not sure this usecase is really useful.
     */
    if (!this.isCheckBlock) {
      this.$watch(`content.${this.item.id}.task.finished`, this.handleTerminalOpening)
      this.$watch(`content.${this.item.id}.resource.finished`, this.handleTerminalOpening)
    }
  },
  methods: {
    ...mapActions('alerts', [
      'SHOW_ALERT',
    ]),
    /*
     * This function is checking that the task is finished, before trying to fetch the index.
     * Else, the virtual scroll list is totally lost about the position as it's changing all the time
     * Also, it's not scrolling on mounted hook because the ref isn't populated yet.
     * Why ? Because the rendering is on a v-if. As the ref is rendered during the v-if the ref is not
     * populated during the mounted hook.
     */
    highlightLine () {
      const [, itemId, lineNumber] = this.$route.hash.match(REGEX.BUILD_LOG_HASH) || []

      if (!this.highlighted) {
        const canHighlightFromHash = checksPass({
          idMatchesHash: !_.$isEmpty(itemId) && (itemId === this.item.id),
          hasScrollIndex: !_.$isEmpty(lineNumber),
          isRefAvailable: this.$refs[`terminal-${this.item.id}`],
          hasTaskOrResourceFinished: _.some([_.get(this.getContent, 'task.finished'), _.get(this.getContent, 'resource.finished')]),
        })
        if (canHighlightFromHash) {
          this.$refs[`terminal-${this.item.id}`].scrollToIndex(Number(lineNumber))
          this.highlighted = true
        }
      }
      return true
    },
    handleTerminalOpening (newValue, oldValue) {
      const [, itemId] = this.$route.hash.match(REGEX.BUILD_LOG_HASH) || []

      const canOpen = checksPass({
        isFinished: !!newValue,
        isChanging: newValue !== oldValue,
        idMatchesHash: itemId === this.item.id,
      })
      if (canOpen) this.open = true
    },
    async clearTaskCache (item) {
      const { orgCanonical, projectCanonical, pipelineCanonical, jobCanonical } = this.$route.params
      this.loadingClearTaskCache = true

      const { data } = await this.$cycloid.ydAPI.clearTaskCache(orgCanonical, projectCanonical, pipelineCanonical, jobCanonical, item.task.name) || {}
      if (data) this.SHOW_ALERT({ type: 'success', content: this.$t('alerts.success.buildLeaf.cachesRemoved', { cachesRemoved: data.caches_removed }) })
      this.loadingClearTaskCache = false
    },
    toggleImageFetching () {
      if (!this.open) {
        this.open = true
      }
      this.openImageFetching = !this.openImageFetching
    },
    isStep (item) {
      if (_.has(item, 'ensure.step')) return item.ensure.step
      if (_.has(item, 'on_success.step')) return item.on_success.step
      if (_.has(item, 'on_failure.step')) return item.on_failure.step
      return false
    },
    isChildren (item) {
      return this.containsInParallel(item) || _.has(item, 'step')
        ? ''
        : 'children'
    },
    isFirstOccurrence (resourceName) {
      const itemInputs = _.get(this.resources, 'inputs', []).find(({ name }) => name === resourceName)
      return !_.isEmpty(itemInputs) && _.get(itemInputs, 'first_occurrence')
    },
    getResourceVersionMetadata (resourceName, version, metadata) {
      /*
       * This function is generic and will allow the template to display metadata
       * (metadata are displayed aside the logs), or display the version (shown
       * on the resource).
       * As it's the same behavior/logic, it didn't make sense to have a different
       * function for getting them, so version and metadata are boolean to know
       * which one this function should return.
       * It will check in the resources object sent by concourse if the resourceName
       * exist in input or outputs, and it will display the version or metadata Object
       * related to this name.
       * Because we also receive information in the eventstream, this function will
       * check in the content (eventstream) if these data exist. All the finish-get
       * and finish-put event use this system to show versions and metadata
       * This function will return a merged object of these versions/metadata
       *
       * No metadata has to be displayed for terracost put task
       */

      const id = this.item.id
      let itemInputs, itemOutputs, itemContent

      if (_.get(this.resources, 'outputs')) {
        itemOutputs = this.resources.outputs.find(({ resource }) => resource === resourceName) || {}
      }
      if (_.get(this.resources, 'inputs')) {
        itemInputs = this.resources.inputs.find(({ resource }) => resource === resourceName) || {}
      }
      if (_.get(this.content[id], 'resource')) itemContent = this.content[id].resource

      if (version && !this.isTerraCostTaskItem) {
        return {
          ...(_.get(itemInputs, 'version', {})),
          ...(_.get(itemOutputs, 'version', {})),
          ...(_.get(itemContent, 'version', {})),
        }
      }

      if (metadata && !this.isTerraCostTaskItem) {
        return {
          ...(_.get(itemInputs, 'metadata', {})),
          ...(_.get(itemOutputs, 'metadata', {})),
          ...(_.get(itemContent, 'metadata', {})),
        }
      }

      return {}
    },
    containsInParallel (obj) {
      for (const prop in obj) {
        if (prop === 'in_parallel' || prop === 'aggregate') return true
        if (_.isPlainObject(obj[prop])) return this.containsInParallel(obj[prop])
      }
      return false
    },
    hookedClass (item) {
      const isHooked = _.$hasAny(item, ['ensure.step', 'on_success.step', 'on_failure.step'])
      return isHooked ? 'hooked' : ''
    },
    getChildrenClass (item, cssClass) {
      return _.$hasAll(item, [cssClass, 'step'])
    },
    isClickableUrl (url) {
      return url.startsWith('http') && this.$sanitizeUrl(url) !== 'about:blank'
    },
    consoleScroll ({ target: elm }) {
      const didScrollToBottom = elm.scrollTop + elm.clientHeight === elm.scrollHeight
      this.autoScroll = didScrollToBottom
    },
  },
  i18n: {
    messages: {
      en: {
        clearCache: 'Clear the task cache',
        imageFetching: 'Show the image fetching steps',
        duration: 'duration',
        errorIconTooltip: 'critical error',
        initialization: 'initialization',
        newVersion: 'New version',
        tryErrorIconTooltip: 'non critical error',
      },
      es: {
        clearCache: 'Borrar el caché de la Task',
        imageFetching: 'Mostrar pasos de inicialización',
        duration: 'duración',
        errorIconTooltip: 'error crítico',
        initialization: 'inicialización',
        newVersion: 'Nueva versión',
        tryErrorIconTooltip: 'error no crítico',
      },
      fr: {
        clearCache: 'Nettoyer le cache de la Task',
        imageFetching: 'Afficher les étapes d\'initialisation',
        duration: 'durée',
        errorIconTooltip: 'erreur critique',
        initialization: 'initialisation',
        newVersion: 'Nouvelle version',
        tryErrorIconTooltip: 'erreur non critique',
      },
    },
  },

}
</script>

<style lang="scss" scoped>
  $ansi-colors: (
    "black": cy-get-color("grey", "terminal"),
    "red": cy-get-color("error", "light-1"),
    "green": cy-get-color("success", "light-1"),
    "yellow": cy-get-color("warning", "light-1"),
    "blue": #8bbdff,
    "magenta": #ff77c9,
    "cyan": #60efef,
    "white": cy-get-color("white"),
    "bright-black": cy-get-color("grey", "dark-2"),
    "bright-red": cy-get-color("error", "light-2"),
    "bright-green": cy-get-color("success", "light-2"),
    "bright-yellow": cy-get-color("warning", "light-2"),
    "bright-blue": #b3d6ff,
    "bright-magenta": #ffc3e7,
    "bright-cyan": #bbf7f7,
    "bright-white": cy-get-color("white")
  );

  // Ansi terminal styles
  $class-prefix: "ansi-";
  $metadata-key-color: cy-get-color("grey", "dark-3");
  $metadata-value-color: cy-get-color("black");
  $metadata-padding: 2px 10px 2px 10px;

  .error-message {
    color: map.get($ansi-colors, "red");
    font-weight: $font-weight-bolder;
  }

  ::v-deep {
    // Decorations
    .#{$class-prefix}bold {
      font-weight: $font-weight-bolder;
    }

    .#{$class-prefix}italic {
      font-style: italic;
    }

    .#{$class-prefix}dim {
      opacity: 0.75;
    }

    .#{$class-prefix}hidden {
      color: map.get($ansi-colors, "black") !important;
    }

    .#{$class-prefix}underline {
      text-decoration: underline;
    }

    .#{$class-prefix}strikethrough {
      text-decoration: line-through;
    }

    // Colors
    @each $name, $value in $ansi-colors {
      .#{$class-prefix}#{$name}-fg {
        color: $value;
      }

      .#{$class-prefix}#{$name}-bg {
        background-color: $value;
      }
    }
  }

  .content {
    background-color: cy-get-color("grey", "terminal");
    color: cy-get-color("white");
    line-height: 1.5;

    &.step-content {
      overflow-x: auto;

      .step-content__code-block {
        display: flex;
        flex-direction: column;
        min-width: 50%;
        color: map.get($ansi-colors, "white");
        font-family: $font-family-code;
      }
    }

    ::v-deep {
      .cy-alert {
        margin: 0;
        border-top: 1px solid cy-get-color("grey", "light-1");
        border-radius: 0 !important;
      }

      .pricing-table {
        margin: 0;
        border-top: 1px solid cy-get-color("grey", "light-1");
        border-radius: 0;
      }
    }
  }

  .ensure {
    border-left: 2px solid #9b59b6;
  }

  .on_success {
    border-left: 2px solid cy-get-color("build", "succeeded");
  }

  .success-icon {
    color: cy-get-color("build", "succeeded");
  }

  .error-icon {
    color: cy-get-color("build", "failed");
  }

  .on_failure {
    border-left: 2px solid cy-get-color("build", "failed");
  }

  .in_parallel {
    border-left: 2px solid #0e9898;
  }

  .children {
    margin-top: 2px;
    margin-left: 1em;
  }

  ::v-deep .v-card {
    &.build-step {
      border: 1px solid lightgray;
    }

    &__title {
      padding: 8px;
    }
  }

  .seq {
    margin-top: 10px;
  }

  .resource-version,
  .step-time {
    font-family: $font-family-code;
    font-size: $font-size-xs;
    font-weight: $font-weight-default;
    line-height: 1.5;
    white-space: nowrap;
  }

  .step-time .dict-value {
    min-width: 40px;
  }

  .dict-key {
    padding-right: 10px;
    text-align: right;
  }

  .dictionary {
    margin: 16px auto 16px 16px;
  }

  .image-fetching {
    margin: 16px;
  }

  .metadata {
    color: cy-get-color("white");
    font-family: $font-family-code;
    font-size: $font-size-sm;
    line-height: 1.5;

    .dict-key {
      padding: $metadata-padding;
      background: $metadata-key-color;
    }

    .dict-value {
      padding: $metadata-padding;
      background-color: $metadata-value-color;
    }

    .cy-link {
      color: cy-get-color("secondary");
      text-decoration: none;

      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  pre {
    font-family: "Roboto Mono", monospace;
    font-size: 12px;
    word-break: break-word;
    white-space: pre-wrap;
  }

  .clearfix::after {
    content: " ";
    display: block;
    visibility: hidden;
    height: 0;
    clear: both;
    font-size: 0;
  }

  .flex-no-grow {
    flex-grow: 0;
  }

  .step-vcard-title {
    padding: 3px;
  }

  .icon-clear-task,
  .icon-image-fetch {
    &::before {
      color: transparent;
    }

    &:hover {
      color: cy-get-color("black", $alpha: 0.7);
    }

    width: 24px;
    height: 24px;
    color: cy-get-color("black", $alpha: 0.5);
  }

  .step-type {
    color: cy-get-color("grey", "dark-2");
  }

  .step-new-version {
    margin-left: 4px;
  }

  .margin-no-newversion {
    margin-left: 20px;
  }

  .started-from-now {
    padding: 0 4px;
    border-radius: 3px;
    color: cy-get-color("grey", "dark-2");
    font-family: $font-family-code;
    font-size: 10px;
    font-style: normal;
    font-weight: normal;
    line-height: 15px;

    &:hover {
      transition: background-color 0.2s ease;
      background-color: cy-get-color("grey", "light-3");
    }
  }

  .virtual-list {
    max-height: 800px;
    overflow-y: auto;

    ::v-deep [role="group"] [role="listitem"] {
      &:first-child {
        margin-top: 16px;
      }

      &:last-child {
        margin-bottom: 16px;
      }
    }
  }
</style>
